<template>
  <q-page padding>
    <h5>Frases App</h5>
    <h6>by Abraão Pinto</h6>

    <p>Lorem ipsum dolor sit amet. Et modi quos ab debitis impedit At doloremque enim vel optio molestiae qui nostrum mollitia. Eum dolores illum ut inventore quis <em>Non aperiam aut error quibusdam et voluptas reiciendis in consectetur molestiae</em>. </p>
    <p>Sit excepturi atque <em>Rerum</em> nam consequuntur possimus sit expedita culpa. Eos exercitationem amet sit autem pariatur <strong>Aut voluptatem est accusantium nemo ut eveniet dolor quo quis galisum</strong>. </p>
    <p>Aut modi maiores vel repudiandae fugiat aut voluptates iste qui galisum dolores id nemo voluptatum vel vero enim sit debitis doloremque. Qui itaque nequeet doloremque qui impedit expedita. </p>
    <p>Vel placeat dolorem sed molestias nemo 33 ipsum voluptatibus id modi adipisci eum maiores incidunt eos deserunt minima. Hic ipsam atque <em>Est praesentium et sunt maxime aut magnam aliquam</em> est dicta culpa et temporibus quia. </p>
    <p>Quo numquam quaerat ad autem illo eum accusamus exercitationem. Nam provident asperiores <em>Nam enim aut similique similique quo itaque consequatur aut quia laudantium</em>? Id aspernatur deserunt aut velit doloremque et reprehenderit quisquam et reiciendis officia. </p>
    <p>Cum laboriosam nesciunt aut vero sequiet illum. Non consequatur porroest quaerat eos quia galisum. Et voluptas quidem ut aliquid voluptatem et dolorum officia ad amet dolore qui dolor voluptatem et amet minima 33 enim placeat. At quas explicabo aut ullam possimus aut dolores provident! </p>
    <p>Sit velit beatae ut quidem excepturi <em>Ut sunt ea ipsam distinctio</em>. Ea incidunt quis ea ipsam nostrum in quia itaque 33 cumque provident! Ut blanditiis pariatur est autem quidem ut nostrum ducimus facilis inventore in dolore dolor qui modi sint? Vel totam quas et quasi veniam ad excepturi corporis. </p>
    <p>Eum beatae dolorem nam ducimus modi qui velit galisum. Sed obcaecati doloremque <strong>Molestiae quo nihil illo id deserunt sunt</strong>! Nam tempora velit qui quia asperiores <em>At reiciendis sit error velit</em> qui voluptates rerum ab minus dolorem in illum quaerat. </p>
    <p>Nam consequatur blanditiis <em>Qui reprehenderit</em> hic enim ratione. Ex ipsa alias qui voluptates dolorem ab officiis quia et voluptate. Et odio perferendis ut dolores harum ad consequuntur aperiam rem sint consequuntur et ullam ducimus sit eveniet dolor a perspiciatis officia. Qui nostrum unde sit galisum quaerat vel facere repellendus ea totam recusandae ea rerum repudiandae. </p>
    <p>Sed fugiat tenetur ab obcaecati tempore est accusantium reprehenderit sit amet explicabo ut amet consequatur qui consequatur recusandae sit eligendi nesciunt. Et pariatur essenon praesentium id expedita. </p>

  </q-page>
</template>
